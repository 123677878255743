import { Cue, MediaConfig } from "@getsubly/common";
import { MediaType } from "@getsubly-libs/media-editor";

const urlPattern =
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/g;

type InputCue = Omit<Cue, "id"> & { id?: string };

export interface ITranscriptionData {
  id: string;
  language: string;
  transcription?: InputCue[];
  srt?: string;
}

export interface IMediaEditorInput {
  mediaType: MediaType;
  mediaUrl: string;
  transcriptions: ITranscriptionData[];
  config: MediaConfig;
}

export interface IMediaEditorSettings {
  allowFullScreen?: boolean;
}

const isTranscriptionData = (data?: any): data is ITranscriptionData =>
  Boolean(
    data.id &&
      typeof data.id === "string" &&
      data.language &&
      typeof data.language === "string" &&
      (data.transcription || (data.srt && typeof data.srt === "string"))
  );

export const isMediaEditorInput = (data?: any): data is IMediaEditorInput =>
  Boolean(
    data &&
      data.mediaType &&
      Object.values(MediaType).includes(data.mediaType) &&
      data.mediaUrl &&
      typeof data.mediaUrl === "string" &&
      Array.isArray(data.transcriptions) &&
      data.transcriptions.every((t: any) => isTranscriptionData(t)) &&
      data.config
  );

export interface ILogoData {
  id: string;
  url: string;
  height: number;
  width: number;
  name?: string;
  filename?: string;
  sizeBytes?: number;
  extension?: string;
  uploadedAt?: Date;
}

const isLogoData = (data?: any): data is ILogoData =>
  Boolean(
    data &&
      data.id &&
      typeof data.id === "string" &&
      data.url &&
      typeof data.url === "string" &&
      urlPattern.test(data.url) &&
      data.height &&
      typeof data.height === "number" &&
      data.width &&
      typeof data.width === "number"
  );

export const isLogoInput = (data?: any): data is ILogoData[] =>
  Boolean(data && Array.isArray(data) && data.every((d) => isLogoData(d)));

export const isMediaEditorSettings = (
  data?: any
): data is IMediaEditorSettings => Boolean(data && typeof data === "object");
