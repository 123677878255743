import { Transcription } from "@getsubly/common";
import { stringify, Captions } from "subtitle";

export const transcriptionToSrt = (transcription: Transcription): string => {
  const cueList: Captions = transcription.map((cue) => ({
    start: cue.start,
    end: cue.end,
    text: cue.text,
  }));

  return stringify(cueList, { format: "srt" });
};
