import React, { useState } from "react";
import {
  DEFAULT_ASPECT_RATIO_CONFIG,
  DEFAULT_CONFIG,
  MediaConfig,
} from "@getsubly/common";
import { parseToTranscription } from "@getsubly/common/dist/assParser";
import { v4 } from "uuid";
import Editor, {
  Asset,
  AssetType,
  BasicMedia,
  LoadingIcon,
  TranscriptionMap,
  IVideoMetadata,
} from "@getsubly-libs/media-editor";
import {
  isLogoInput,
  isMediaEditorInput,
  ILogoData,
  ITranscriptionData,
  isMediaEditorSettings,
  IMediaEditorSettings,
} from "./types";
import { transcriptionToSrt } from "./types/file";
import { useDomainWhitelist } from "./hooks/use-domain-whitelist";
import { useWindowMessage } from "./hooks/use-window-message";
import { getTranscriptionDurationMs } from "./utils/transcription";

const whitelist = process.env.REACT_APP_WHITELISTED_DOMAINS?.split(",") || [];

const App: React.FC = () => {
  const [transcriptions, setTranscriptions] = useState<TranscriptionMap>();
  const [media, setMedia] = useState<BasicMedia>();
  const [mediaConfig, setMediaConfig] = useState<MediaConfig>();
  const [customLogos, setCustomLogos] = useState<Asset[]>([]);
  const [allowFullScreen, setAllowFullScreen] = useState(false);
  const [getCurrentEnabled, setGetCurrent] = useState(false);
  const allowedOrigin = document.referrer.replace(/\/\s*$/, "");
  const { isWhitelisted } = useDomainWhitelist(whitelist);

  const handleLogos = (data: ILogoData[]) => {
    console.log("Adding custom logos");
    setCustomLogos(
      data.map(
        ({
          id,
          url,
          height,
          width,
          name,
          filename,
          sizeBytes,
          extension,
          uploadedAt,
        }) => ({
          id,
          s3Uri: url,
          publicUrl: url,
          height,
          width,
          type: AssetType.LOGO,
          // The following is not used in the editor by in Asset type
          name: name || "",
          sizeBytes: sizeBytes || 0,
          filename: filename || "",
          extension: extension || "",
          uploadedAt: uploadedAt || new Date(),
        })
      )
    );
  };

  const handleSettings = (settings: IMediaEditorSettings) => {
    console.log("handle settings");

    setAllowFullScreen(Boolean(settings.allowFullScreen));
  };

  const handleMessage = (e: MessageEvent) => {
    if (e.data.type === "transcription" && isMediaEditorInput(e.data.data)) {
      const {
        transcriptions: newTranscriptions,
        mediaType,
        mediaUrl,
        config,
      } = e.data.data;
      const transcriptionMap = newTranscriptions.reduce(
        (acc: TranscriptionMap, t: ITranscriptionData) => {
          const transcription = t.transcription
            ? [...t.transcription]
            : parseToTranscription(t.srt || "");

          if (!transcription) {
            // ignore
            return acc;
          }

          return {
            ...acc,
            [t.id]: transcription.map((tr) => ({ id: v4(), ...tr })),
          };
        },
        {} as TranscriptionMap
      );

      setTranscriptions(transcriptionMap);

      const newMediaId = v4();
      // Get duration from transcription
      const durationMs = getTranscriptionDurationMs(
        transcriptionMap[Object.keys(transcriptionMap)[0]]
      );

      setMedia({
        mediaId: newMediaId,
        videoUrl: mediaUrl,
        type: mediaType,
        duration: durationMs / 1000,
        transcriptions: {
          // TODO: Handle translations
          originalId: newTranscriptions[0].id,
          original: {
            id: newTranscriptions[0].id,
            code: "en-GB",
            language: "English (GB)",
          },
          translationsIds: [],
          translations: [],
        },
      });
      setMediaConfig({
        ...DEFAULT_CONFIG,
        showWatermark: false,
        ...config,
        aspectRatio: {
          ...DEFAULT_ASPECT_RATIO_CONFIG,
          ...(config?.aspectRatio || {}),
        },
      });
    } else if (e.data.type === "logos" && isLogoInput(e.data.data)) {
      handleLogos(e.data.data);
    } else if (
      e.data.type === "settings" &&
      isMediaEditorSettings(e.data.data)
    ) {
      handleSettings(e.data.data);
    } else if (e.data.type === "getCurrent") {
      setGetCurrent(true);
      setGetCurrent(false);
    } else {
      console.log("unknown event", e.data.type);
      console.log("data", e.data.data);
    }
  };

  useWindowMessage(allowedOrigin, handleMessage);

  if (!isWhitelisted) {
    return (
      <div className="medium m-3">
        <LoadingIcon /> Loading...
      </div>
    );
  }

  return (
    <Editor
      transcriptions={transcriptions}
      media={media}
      sublyWatermark={false}
      mediaConfig={mediaConfig}
      logoAssets={customLogos}
      allowFullScreen={allowFullScreen}
      onUpdateConfig={setMediaConfig}
      getCurrentEnabled={getCurrentEnabled}
      onSave={async (
        newTranscriptions: TranscriptionMap,
        newConfig: MediaConfig,
        videoMetadata: IVideoMetadata
      ) => {
        window.parent.postMessage(
          {
            type: "transcription",
            data: {
              transcriptions: Object.keys(newTranscriptions).map((key) => ({
                id: key,
                transcription: newTranscriptions[key],
                srt: transcriptionToSrt(newTranscriptions[key]),
              })),
              config: newConfig,
              video: videoMetadata,
            },
          },
          document.referrer
        );
      }}
    />
  );
};

export default App;
