import { useEffect, useState } from "react";

interface IUseDomainWhitelist {
  isWhitelisted: boolean;
}

export function useDomainWhitelist(allowedDomains: string[]): IUseDomainWhitelist {
  const { hostname: parentHostname }=  new URL(document.referrer);
  const [isWhitelisted, setIsWhitelisted] = useState(false);


  useEffect(() => {
    console.log('checking hostname valid', parentHostname);
    const isAllowedHostname = allowedDomains.length < 1 || allowedDomains.includes(parentHostname);

    setIsWhitelisted(isAllowedHostname);
  }, [parentHostname]); // eslint-disable-line react-hooks/exhaustive-deps

  return { isWhitelisted };
}
