import { useEffect } from "react";

export function useWindowMessage(
  allowedOrigin: string,
  msgHandler: (event: MessageEvent) => void
): void {
  const isClient = typeof window === "object";

  useEffect(() => {
    if (!isClient) {
      return;
    }

    function handleMessage(e: MessageEvent) {
      if (e.origin !== allowedOrigin) {
        console.log("invalid origin", e.origin);
        return;
      }

      msgHandler(e);
    }

    window.addEventListener("message", handleMessage, false);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [allowedOrigin, isClient]); // eslint-disable-line react-hooks/exhaustive-deps

  return;
}
